/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'gatsby';
import { css } from "@emotion/core";
import NetlifyForm from 'react-netlify-form';
// import Form from 'carbon-components-react/lib/components/Form';
import Button from 'carbon-components-react/lib/components/Button';
import TextArea from 'carbon-components-react/lib/components/TextArea';
import TextInput from 'carbon-components-react/lib/components/TextInput';
// import Checkbox from 'carbon-components-react/lib/components/Checkbox';
// import FormGroup from 'carbon-components-react/lib/components/FormGroup';
// import FileUploader from 'carbon-components-react/lib/components/FileUploader';
// import NumberInput from 'carbon-components-react/lib/components/NumberInput';
// import RadioButton from 'carbon-components-react/lib/components/RadioButton';
// import RadioButtonGroup from 'carbon-components-react/lib/components/RadioButtonGroup';
// import Search from 'carbon-components-react/lib/components/Search';
// import Select from 'carbon-components-react/lib/components/Select';
// import SelectItem from 'carbon-components-react/lib/components/SelectItem';
// import Toggle from 'carbon-components-react/lib/components/Toggle';

class ContactForm extends React.Component {
  render() {
    const recaptchaKey = process.env.NODE_ENV==='development'?'zzz' : process.env.GATSBY_SITE_RECAPTCHA_KEY;
    const recaptchaPars = {
      sitekey: recaptchaKey,
      size: 'invisible',
      theme: 'dark'
    };
    return (
      <div>
        <div id="root"></div>
        <NetlifyForm
          name="Form With Recaptcha"
          recaptcha={recaptchaPars}>
          {({ loading, error, recaptchaError, success, recaptcha }) => (
            <div>
              {loading && <div>Loading...</div>}
              {error && (
                <div>
                  Your information was not sent. Please try again later.
                </div>
              )}
              {recaptchaError && (
                <div>
                  Recaptcha did not match. Please make sure the box is checked.
                </div>
              )}
              {success && <div>Thank you for contacting us!</div>}
              {!loading && !success && (
                <div>
                  <TextInput
                    className="some-class"
                    disabled={false}
                    helperText=""
                    id="fullname"
                    name="fullname"
                    invalid={false}
                    invalidText=""
                    // labelText="Full Name"
                    light={false}
                    onChange={function noRefCheck() {}}
                    onClick={function noRefCheck() {}}
                    placeholder="Full Name"
                    type="text"
                    required
                  />
                  <TextInput
                    className="some-class"
                    disabled={false}
                    helperText=""
                    id="companyname"
                    name="companyname"
                    invalid={false}
                    invalidText=""
                    // labelText="Company Name"
                    light={false}
                    onChange={function noRefCheck() {}}
                    onClick={function noRefCheck() {}}
                    placeholder="Company Name"
                    type="text"
                  />
                  <TextInput
                    className="some-class"
                    disabled={false}
                    helperText=""
                    id="phonenumber"
                    name="phonenumber"
                    invalid={false}
                    invalidText=""
                    // labelText="Text Input label"
                    light={false}
                    onChange={function noRefCheck() {}}
                    onClick={function noRefCheck() {}}
                    placeholder="Phone Number"
                    type="text"
                  />
                  <TextInput
                    className="some-class"
                    disabled={false}
                    helperText=""
                    id="email"
                    name="email"
                    invalid={false}
                    invalidText=""
                    placeholder="Email"
                    // labelText="Email"
                    light={false}
                    onChange={function noRefCheck() {}}
                    onClick={function noRefCheck() {}}
                    type="email"
                    required
                  />
                  <TextArea
                    className="some-class"
                    cols={50}
                    disabled={false}
                    helperText=""
                    id="message"
                    name="message"
                    invalid={false}
                    invalidText=""
                    // labelText="Message"
                    light={false}
                    onChange={function noRefCheck() {}}
                    onClick={function noRefCheck() {}}
                    placeholder="Message"
                    rows={4}
                  />
                  <p>
                    <br/>
                    By submitting this form I give my consent for Wizbord to process my
                    personal data pursuant to <Link to="/privacy">Privacy Policy</Link>.
                    <br/><br/>
                  </p>
                  <Button
                    className="contact-form-button"
                    disabled={false}
                    kind="primary"
                    tabIndex={0}
                    type="submit">
                    Submit
                  </Button>
                </div>
              )}
              {recaptcha}
            </div>
          )}
        </NetlifyForm>
      </div>
    );
  }
}

export default ContactForm;
