import React from 'react';
// import icons
import {
  FaTwitter,
  FaFacebook,
  FaEnvelope,
  FaPinterest,
  FaLinkedin,
} from 'react-icons/fa';

// import react-custom-share components
import { ShareButtonRectangle, ShareBlockStandard } from 'react-custom-share';

// chage later for header banners
import cMedia from '../../images/favicon-512.png';

const wHref = typeof window !== `undefined` ? window.location.href : '';
// const wHref = window.location.href;
const clnUrl = wHref.split('#')[0];
// const cMedia = clnUrl + 'media.jpg'

export const shareBlockProps = {
  url: clnUrl,
  button: ShareButtonRectangle,
  buttons: [
    { network: 'Twitter', icon: FaTwitter },
    { network: 'Facebook', icon: FaFacebook },
    { network: 'Email', icon: FaEnvelope },
    {
      network: 'Pinterest',
      icon: FaPinterest,
      media: cMedia,
    },
    { network: 'Linkedin', icon: FaLinkedin },
  ],
  text: `Give it a try - wizbord.com `,
  longtext: `Take a look at this super website I have just found.`,
};

class Share extends React.Component {
  render() {
    return (
      <div>
        <ShareBlockStandard {...shareBlockProps} />
      </div>
    );
  }
}

export default Share;
