/* eslint-disable react/no-danger */
import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

//const htmlContent = require('../../../public/files/purechat.html');

const currentYear = new Date().getFullYear();

const Content = ({ buildTime }) => (
  <>
    <p>
      Have questions? <a href="mailto:info@wizbord.com">Email</a> us 
    </p>
    <p>
      Last updated {buildTime}
      <br />
      Copyright © {currentYear} Wizbord Inc.
    </p>
  </>
);

const links = {
  firstCol: [
    {
      href: '/privacy',
      linkText: 'Privacy Policy',
    },
    {
      href: '/cookies',
      linkText: 'Cookies Policy',
    },
  ],
  secondCol: [
    { href: 'https://twitter.com/wizbord', linkText: 'Twitter' },
    { href: 'https://www.linkedin.com/company/wizbord', linkText: 'LinkedIn' },
    { href: 'https://www.facebook.com/wizbord', linkText: 'Facebook' },
  ],
};

const myLogo = () => (
  <svg
    width="100"
    height="100"
    version="1.1"
    viewBox="0 0 40 30"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient
        id="linearGradient486"
        x2="1"
        gradientTransform="matrix(-53.507 74.769 74.769 53.507 788.06 873.02)"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#9821a6" offset="0" />
        <stop stopColor="#00cdea" offset="1" />
      </linearGradient>
      <linearGradient
        id="linearGradient506"
        x2="1"
        gradientTransform="matrix(-53.807 75.188 75.188 53.807 767.85 857.86)"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#9821a6" offset="0" />
        <stop stopColor="#00cdea" offset="1" />
      </linearGradient>
    </defs>
    <g id="g18" transform="matrix(.35278 0 0 -.35278 -247.51 336.75)">
      <path
        id="path16"
        fill="url(#linearGradient486)"
        d="m732.39 917.6-15.944 33.363-9.655-20.221 22.69-47.489v-5e-3h4e-3l15.33 32.095 5.187 1.618-15.979-1.348 7.945 5.762 8.932 2.949 19.622-41.076 22.69 47.494-9.656 20.221-15.939-33.363-17.615 36.873z"
      />
    </g>
    <g id="g22" transform="matrix(.35278 0 0 -.35278 -247.51 336.75)">
      <path
        id="path20"
        fill="url(#linearGradient506)"
        d="m783.7 888.2c2.934-0.736 5.878-1.523 8.592-2.716 0.672-0.302 1.333-0.616 1.933-0.985 0.59-0.36 1.162-0.794 1.444-1.206 0.14-0.203 0.174-0.355 0.161-0.411 1e-3 -0.053-0.033-0.13-0.153-0.299-0.247-0.335-0.817-0.73-1.402-1.039-1.213-0.623-2.612-1.096-4.021-1.496-1.415-0.398-2.869-0.716-4.332-0.996-5.872-1.064-11.867-1.693-17.87-2.048-6.005-0.369-12.029-0.547-18.052-0.539-3.013-5e-3 -6.024 0.038-9.033 0.133-3.011 0.08-6.017 0.227-9.019 0.415-6 0.386-12 0.973-17.869 2.044-1.463 0.281-2.917 0.599-4.332 0.996-1.41 0.398-2.806 0.874-4.02 1.496-0.585 0.309-1.155 0.703-1.4 1.039-0.121 0.166-0.153 0.242-0.152 0.293-0.013 0.054 0.019 0.206 0.16 0.408 0.279 0.411 0.852 0.846 1.441 1.206 0.599 0.368 1.261 0.683 1.933 0.984 2.714 1.193 5.657 1.982 8.591 2.718 2.945 0.721 5.927 1.316 8.92 1.89-3.04-0.107-6.075-0.348-9.107-0.74-1.519-0.187-3.029-0.446-4.541-0.749-1.509-0.308-3.018-0.67-4.522-1.175-0.751-0.254-1.497-0.555-2.243-0.935-0.743-0.399-1.5-0.818-2.209-1.652-0.34-0.417-0.689-1.012-0.757-1.774-0.082-0.766 0.203-1.489 0.516-1.977 0.657-0.988 1.412-1.479 2.139-1.961 1.466-0.907 2.963-1.473 4.459-1.99 1.498-0.509 3.002-0.923 4.51-1.297 3.015-0.747 6.046-1.301 9.085-1.747 3.039-0.455 6.086-0.787 9.133-1.062 3.05-0.271 6.103-0.45 9.154-0.587 3.054-0.12 6.108-0.18 9.163-0.187 6.108 0.013 12.218 0.238 18.315 0.783 3.049 0.281 6.093 0.63 9.133 1.069 3.04 0.434 6.071 0.992 9.086 1.737 1.507 0.375 3.011 0.788 4.509 1.296 1.497 0.515 2.991 1.085 4.458 1.99 0.725 0.482 1.481 0.974 2.136 1.959 0.313 0.487 0.597 1.209 0.515 1.974-0.067 0.76-0.415 1.354-0.756 1.77-0.706 0.834-1.463 1.252-2.205 1.651-0.746 0.381-1.492 0.682-2.244 0.935-1.504 0.504-3.011 0.868-4.521 1.176-1.511 0.305-3.022 0.563-4.54 0.75-3.031 0.392-6.068 0.635-9.107 0.743 2.994-0.572 5.976-1.167 8.921-1.887"
      />
    </g>
  </svg>
);

const CustomFooter = () => (
  <>
    {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
    <Footer links={links} Content={Content} Logo={myLogo} />
  </>
);

export default CustomFooter;
