// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-migrate-to-php-7-index-mdx": () => import("./../../../src/pages/blog/migrate-to-php-7/index.mdx" /* webpackChunkName: "component---src-pages-blog-migrate-to-php-7-index-mdx" */),
  "component---src-pages-company-about-mdx": () => import("./../../../src/pages/company/about.mdx" /* webpackChunkName: "component---src-pages-company-about-mdx" */),
  "component---src-pages-company-approach-mdx": () => import("./../../../src/pages/company/approach.mdx" /* webpackChunkName: "component---src-pages-company-approach-mdx" */),
  "component---src-pages-company-careers-mdx": () => import("./../../../src/pages/company/careers.mdx" /* webpackChunkName: "component---src-pages-company-careers-mdx" */),
  "component---src-pages-company-team-copy-mdx": () => import("./../../../src/pages/company/team copy.mdx" /* webpackChunkName: "component---src-pages-company-team-copy-mdx" */),
  "component---src-pages-company-team-mdx": () => import("./../../../src/pages/company/team.mdx" /* webpackChunkName: "component---src-pages-company-team-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-expertise-mdx": () => import("./../../../src/pages/expertise.mdx" /* webpackChunkName: "component---src-pages-expertise-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-application-mdx": () => import("./../../../src/pages/services/application.mdx" /* webpackChunkName: "component---src-pages-services-application-mdx" */),
  "component---src-pages-services-enterprise-mdx": () => import("./../../../src/pages/services/enterprise.mdx" /* webpackChunkName: "component---src-pages-services-enterprise-mdx" */),
  "component---src-pages-services-mobile-mdx": () => import("./../../../src/pages/services/mobile.mdx" /* webpackChunkName: "component---src-pages-services-mobile-mdx" */),
  "component---src-pages-services-progressive-mdx": () => import("./../../../src/pages/services/progressive.mdx" /* webpackChunkName: "component---src-pages-services-progressive-mdx" */),
  "component---src-pages-services-prototyping-mdx": () => import("./../../../src/pages/services/prototyping.mdx" /* webpackChunkName: "component---src-pages-services-prototyping-mdx" */),
  "component---src-pages-services-staff-mdx": () => import("./../../../src/pages/services/staff.mdx" /* webpackChunkName: "component---src-pages-services-staff-mdx" */),
  "component---src-pages-store-index-mdx": () => import("./../../../src/pages/store/index.mdx" /* webpackChunkName: "component---src-pages-store-index-mdx" */),
  "component---src-pages-store-paymentreceipt-js": () => import("./../../../src/pages/store/paymentreceipt.js" /* webpackChunkName: "component---src-pages-store-paymentreceipt-js" */)
}

