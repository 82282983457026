  
import React, { useEffect, useState } from "react"
import PaymentForm, { loadSquareSdk } from "./PaymentForm"

const PaymentTile = ({ pageContext, ...rest }) => {
  const [squareStatus, setSquareStatus] = useState(null)

  useEffect(() => {
    loadSquareSdk()
      .then(() => {
        setSquareStatus("SUCCESS")
      })
      .catch(() => setSquareStatus("ERROR"))
  }, []) // on mount, add the js script dynamically

  return (
    <div {...rest} className="payment-tile">
            {
        squareStatus==="notLoaded" && (
          <>
            <h3 style={{textAlign:'left'}}>Please hold a moment while we're presently setting up your payment!</h3>
            <h3 style={{textAlign:'left'}}>Thank you for your patience</h3>
          </>
        )
      }
      {squareStatus === "ERROR" &&
        "Failed to load SquareSDK. Please refresh the page."}
      {squareStatus === "SUCCESS" && (
        <PaymentForm paymentForm={window.SqPaymentForm} ammount={rest.ammount} />
      )}
    </div>
  )
}

export default PaymentTile;